$whiteColor: #fff;
$color4a4844: #4a4844;
$widthfull: 100%;
$heightfull: 100%;
$displayFlex: flex;
$align-items-center: center;
$position-absolute: absolute;
$justify-content-center: center;
/* ------------------------------ Service, Login and singup page css  Start-------------------------------*/

[type="password"]:focus {
  --tw-ring-offset-shadow: none !important;
  --tw-ring-color: none !important;
}
[type="number"]:focus {
  --tw-ring-offset-shadow: none !important;
  --tw-ring-color: none !important;
}
// service-page
.service-page-bg {
  background-color: $whiteColor;
  display: $displayFlex;
  align-items: $align-items-center;
  justify-content: $justify-content-center;
  border-radius: 100% 0% 100% 0% / 0% 8% 92% 100%;

  .heading-service {
    width: $widthfull;
    display: $displayFlex;
    justify-content: $justify-content-center;
  }
}
.servicelist {
  width: 75%;
  display: $displayFlex;
  justify-content: flex-start;
  position: $position-absolute;
  align-items: $align-items-center;
  bottom: 15%;
  overflow-x: auto;
}
//   service-page   end

// login page

// .signeuppage {
//   padding-left: 12rem;
//   padding-right: 12rem;
// }

// login end

.loginpage {
  height: $heightfull;
  display: $displayFlex;
  flex-direction: column;
  justify-content: $justify-content-center;
  align-items: $align-items-center;
  position: $position-absolute;
  top: 0;
  right: 13px;
}

.slideshow {
  display: $displayFlex;
  flex-direction: column;
  justify-content: $justify-content-center;
  margin: 0 auto;
  overflow: hidden;
  width: 65%;
}
.slideshowSlider {
  transition: ease 1000ms;
  white-space: nowrap;
}
.slideshowDot.active {
  height: 5px;
  width: 12px;
  border-radius: 10px;
  background-color: $whiteColor;
  cursor: pointer;
  margin: 9px 3px 0px;
}

.slide {
  display: inline-block;
  width: $widthfull;
  border-radius: 10px;
  text-align: center;
}

.slideshowDots {
  text-align: center;
}
.slideshowDot {
  display: inline-block;
  height: 6px;
  width: 6px;
  border-radius: 100%;
  cursor: pointer;
  margin: 9px 3px 0px;
  background-color: #e7e3e3cf;
}
.box {
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
}
.bounce-1 {
  animation-name: bounce-1;
  animation-timing-function: linear;
}
@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

.MuiOutlinedInput-notchedOutline {
  border-color: $color4a4844 !important;
}

.MuiFilledInput-root {
  border-bottom: 1px solid $color4a4844 !important;
}

.MuiInput-root {
  border-bottom: 1px solid $color4a4844 !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: $color4a4844 !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 15px !important;
  font-family: "OpenSans-Regular" !important;
}

.mt-text-from > .textfield {
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 700px) {
  .slideshow {
    width: $widthfull;
  }
  .sildershow {
    width: $widthfull;
  }
  .signeuppage {
    width: $widthfull;
    padding-left: 3%;
    padding-right: 3%;
  }
  .loginlayout {
    flex-direction: column;
  }
}
@media screen and (min-width: 701px) {
  .loginlayout {
    flex-direction: row-reverse;
  }
}
.generator__shape--8v {
  border-radius: 74% 26% 39% 62% / 62% 58% 39% 44%;
  background-color: $whiteColor;
  width: 50px;
  height: 50px;
}

.bg-card-sev {
  width: 295px;
  min-width: 295px;
}
.bottom-left {
  left: 10%;
}
.bottom-right {
  right: 10%;
}
.scroll-button {
  position: absolute;
  bottom: 33%;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.servicelist::-webkit-scrollbar {
  width: 4px;
  height: 7px;
  margin: 20px;
  display: none;
}
.servicelist::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(121, 120, 120, 0.483);
}
.servicelist::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(121, 120, 120, 0.472);
}
.servicelist::-webkit-scrollbar-thumb:hover {
  background: rgba(121, 120, 120, 0.425);
}
.servicelist::-webkit-scrollbar-thumb:active {
  background: rgba(121, 120, 120, 0.475);
}
.bottom-left > .MuiSvgIcon-root {
  color: #48443d;
}
.bottom-right > .MuiSvgIcon-root {
  color: #48443d;
}
/* ------------------------------Service, Login and singup page css  Start-------------------------------*/
/* ------------------------------project card css start--- aman -------------------------------*/
.pro-card {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 1px;
  border: 1px dashed #e8e5dd;
}
.menubarsiderlogo {
  display: none;
}
#header-bar {
  display: none;
}
#pdf-download {
  display: none;
}

.typesOf {
  position: relative;
  bottom: 1.1em;
  left: 7px;
  display: $displayFlex;
  box-shadow: rgba(255, 255, 255, 0.16) 0px 3px 6px, rgba(255, 255, 255, 0.23) 0px 3px 6px;
  padding-left: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 10px;
  color: $whiteColor;
  font-size: 15px;
  background-color: #8cc284;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 2px 2px 8px 8px;
}

.play-btn {
  width: fit-content;
  color: $whiteColor;
  background-color: $color4a4844;
  padding: 3px 12px 3px 12px;
}
.react-pdf__Page__canvas {
  width: 100% !important;
}
/* .react-pdf__Document {
    border: 2px solid $color4a4844;
  } */
.left-border-box {
  border-left: 2px solid #d5d4d2b5;
}
.padding-btn2 {
  background: #ffffff;
  padding: 3px 12px 3px 12px;
}

.Compare-btn {
  width: fit-content;
  color: #000000;
  background: #ccc8c8;
  border: 0px solid $color4a4844;
  padding: 3px 12px 5px 12px;
}
.Compare-btn:hover {
  color: #676662;
}
.pro-map {
  border: 1px dashed $color4a4844;
}
.right-arrow-close {
  display: none;
}
.sidebarbuty {
  width: $widthfull;
  display: $displayFlex;
  padding: 5px;
  justify-content: $justify-content-center;
}
/* ------------------------------project card css end--- aman -------------------------------*/
/* start of phone styles */
@media screen and (max-width: 500px) {
  .sidebr-project-dashbord {
    margin-left: 0;
    width: $widthfull;
    padding: 1%;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-bottom: 15%;
  }
  .menubarsiderlogo {
    display: block !important;
  }
  .sidebarbuty {
    height: 10%;
    display: $displayFlex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: $align-items-center;
    border-bottom: 2px dashed #d1cfcf;
  }
  .right-arrow-close {
    display: block;
  }
  .navbar-section {
    display: $displayFlex;
  }
  .menubarsiderlogo {
    width: 30%;
  }
  .tabletview {
    padding: 0px;
  }

  .modelvideoClip {
    width: 100% !important;
  }

  .sidebr-project-dash {
    display: none;
    padding: 12px;
    width: -webkit-fill-available;
    width: $widthfull;
    z-index: 99;
    top: 0px;
  }
  .sidebarshow {
    display: $displayFlex;
  }
  .sidebr-project-dash {
    animation: 1.2s slide-right;
  }
  @keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0%;
    }
  }
}
/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 501px) and (max-width: 767px) {
  .sidebr-project-dash {
    display: none;
    padding: 12px;
    width: -webkit-fill-available;
    width: 70%;
    top: -9px;
    z-index: 99;
  }
  .menubarsiderlogo {
    display: block !important;
  }
  .sidebarbuty {
    height: 10%;
    display: $displayFlex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: $align-items-center;
    border-bottom: 2px dashed #d1cfcf;
  }
  .right-arrow-close {
    display: block;
  }
  .sidebarbgcolor {
    top: 0px;
    height: $heightfull;
    z-index: 7;
    position: $position-absolute;
    width: $widthfull;
    background-color: rgba(0, 0, 0, 0.5);
    animation: 1.2s;
    transition: 0.9s;
  }
  .tabletview {
    padding: 0px;
  }
  .modelvideoClip {
    width: 100% !important;
  }
  .menubarsiderlogo {
    width: 30%;
  }
  .sidebr-project-dash {
    animation: 0.9s slide-right;
  }
  @keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0%;
    }
  }
  .sidebarshow {
    display: $displayFlex;
  }
  .project-dash::before {
    content: "";
    position: $position-absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: $widthfull;
    height: $heightfull;
    opacity: 0.4;
    overflow: hidden;
    cursor: none;
    right: 0;
    z-index: 1;
  }

  .navbar-section {
    display: $displayFlex;
  }
  .tabletview {
    display: $displayFlex;
    margin-bottom: 10px;
    flex-direction: column;
  }
  .tabletview-card {
    width: $widthfull;
    margin-top: 8px;
  }
  .sidebr-project-dashbord {
    width: $widthfull;
    overflow-y: scroll;
    padding: 4px 7px 4px 7px;
    margin-left: 0;
    margin-bottom: 8%;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1178px) {
  .tabletview {
    display: grid;
    grid-template-rows: repeat(3, minmax(0, 1fr));
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .menubarsiderlogo {
    display: none;
  }
  .tabletview-card {
    height: 262px;
    margin-top: 8px;
  }
  .tablet-portrait-mode {
    margin-left: 0;
    margin-right: 0;
  }
  .tablet-portrait-mode > .user-details {
    font-size: 20px;
    font-weight: 500;
  }
  .tablet-portrait-mode-username {
    font-size: 13px;
  }
  .tablet-portrait-mode-icon {
    font-size: 17px !important;
  }
  .user-details::after {
    content: "";
    margin-left: 4px;
    margin-right: 4px;
    border-right: 0.4px dashed #bdbdbd;
  }
  .parent-text-nav .text-nav {
    font-size: 15px;
  }
  .font-icon {
    width: 14px;
  }
}
.animatformodel {
  animation: fadeInRight 1.1s ease-in-out;
}

@keyframes fadeInRight {
  from {
    transform: translateX(100%);
  }
  to {
    opacity: 1;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1179px) and (max-width: 1280px) {
  .menubarsiderlogo {
    display: none;
  }
}
/* end of phone styles */

.viewbtnformap {
  position: $position-absolute;
  top: 0;
  margin: 19px 12px;
  z-index: 5;
  border-radius: 50%;
  height: 30px;
  background: #fff;
  width: 30px;
  align-items: $align-items-center;
  display: $displayFlex;
  justify-content: $justify-content-center;
}
.loader {
  position: $position-absolute;
  top: 0;
  align-items: $align-items-center;
  display: $displayFlex;
  z-index: 1;
  background: #000;
  width: $widthfull;
  height: $heightfull;
  justify-content: $justify-content-center;
}
.detailscardproject {
  width: -webkit-fill-available;
  padding: 8px;
  border-radius: 0px 0px 3px 3px;
}
.mab_project > .rounded-md {
  border-radius: 3px 3px 0px 0px;
}

/* video */

.video-canvas-respo-view {
  max-height: 85%;
}
.h-inherit {
  height: 273px;
}
@media screen and (max-width: 700px) {
  .video-canvas-respo-view {
    flex-direction: column-reverse;
    height: 69%;
    flex-grow: 1;
  }
  .sidepanelmview {
    height: 7%;
    width: $widthfull;
  }
  .pro-card {
    height: $heightfull;
  }
  .canvas-video-respo {
    border-radius: 12px;
    padding: 8px;
    height: $heightfull;
    width: 100% !important;
  }
  .parent-canvas-video-respo {
    width: $widthfull;
    margin-left: 0rem !important;
    background-color: $whiteColor;
    height: 50% !important;
  }
  .hight-123 {
    height: 50% !important;
    width: $widthfull;
  }
  .mgt-8 {
    margin-top: 8px;
  }
  .formobilevew {
    height: calc(100% - 60px);
    flex-direction: column;
  }
  .sidepanel {
    display: $displayFlex;
    flex-direction: row;
  }
  .sidepanel > div {
    margin-left: 2%;
  }
  .side-btn {
    width: 45px;
  }
  .mobile-video-control {
    margin: 5px !important;
    justify-content: $justify-content-center;
    height: 15%;
  }
  .canvascontrol-mv {
    padding: 10px !important;
  }
  .rangeofvideo {
    margin-bottom: 34px;
  }
  .video-layer-images,
  .action-name {
    display: none;
  }

  .fordevice1000-1300 {
    height: none !important;
    margin-top: 0 !important;
  }
  .videoandAnnotations {
    height: 86%;
    width: $widthfull;
  }
}
.fordevice1000-1300 {
  font-size: 13px;
  height: 30px;
}

@media screen and (min-width: 701px) and (max-width: 1000px) {
  .video-canvas-respo-view {
    flex-direction: column-reverse;
    height: 69%;
    flex-grow: 1;
  }
  .parent-canvas-video-respo {
    width: $widthfull;
  }
  .sidepanelmview {
    height: 7%;
    width: $widthfull;
  }
  .pro-card {
    height: $heightfull;
  }
  .canvas-video-respo {
    border-radius: 12px;
    padding: 8px;
    height: $heightfull;
    width: 100% !important;
  }
  .parent-canvas-video-respo {
    margin-left: 0rem !important;
    background-color: $whiteColor;
    height: 50% !important;
  }
  .hight-123 {
    height: 50% !important;
    width: $widthfull;
  }
  .mgt-8 {
    margin-top: 8px;
  }
  .formobilevew {
    height: calc(100% - 60px);
    flex-direction: column;
  }
  .sidepanel {
    display: $displayFlex;
    flex-direction: row;
  }
  .sidepanel > div {
    margin-left: 2%;
  }
  .side-btn {
    width: 45px;
  }
  .mobile-video-control {
    margin: 5px !important;
    justify-content: $justify-content-center;
    height: 15%;
  }
  .canvascontrol-mv {
    padding: 10px !important;
  }
  .rangeofvideo {
    margin-bottom: 34px;
  }
  .video-layer-images,
  .action-name {
    display: none;
  }

  .fordevice1000-1300 {
    height: none !important;
    margin-top: 0 !important;
  }
  .videoandAnnotations {
    height: 86%;
    width: $widthfull;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1500px) {
  .video-canvas-respo-view {
    max-height: 85%;
  }
  .canvascontrol-mv {
    padding-top: 2px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .formobilevew {
    height: calc(100% - 60px);
  }

  .fordevice1000-1300 {
    font-size: 12px;
    height: 30px;
  }
  .range-for-device {
    margin-top: 1px !important;
  }
  .rangeofvideo {
    height: 21px;
  }
  .video-layer-images:hover {
    transition: 0.2s;
    border: 3px solid #ffca00;
    height: 40px;
  }
  .video-layer-images {
    margin-top: 17px;
    margin-bottom: 8px;
    height: 40px;
  }
  .custom-iconsize > svg {
    width: 24px;
    height: 24px;
  }
  .hight-123 {
    width: 40% !important;
  }
  .parent-canvas-video-respo {
    padding: 5px !important;
    width: 99% !important;
  }
}

@media screen and (min-width: 1501px) and (max-width: 1800px) {
  .video-canvas-respo-view {
    max-height: 75%;
  }
  .formobilevew {
    height: calc(100% - 60px);
  }
  .pro-card {
    height: fit-content;
  }
}
@media screen and (min-width: 1801px) and (max-width: 2000px) {
  .parent-canvas-video-respo {
    width: 89% !important;
  }
  .video-canvas-respo-view {
    max-height: 80%;
  }
  .formobilevew {
    height: calc(100% - 60px);
  }
  .pro-card {
    height: fit-content;
  }
}
@media screen and (min-width: 2001px) {
  .video-canvas-respo-view {
    max-height: 85%;
  }
  .formobilevew {
    height: calc(100% - 60px);
  }
  .parent-canvas-video-respo {
    width: 100% !important;
  }
  .pro-card {
    height: fit-content;
  }
}

/* 
    custom  scrollbar */

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
.custom-scrollbar::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

.no-track::-webkit-scrollbar-track {
  display: none;
}
.earth-background {
  background: radial-gradient(circle at 50% 90%, #356b36 5%, #407c40 15%, #4e8c4e 30%, #5ba95b 60%);
}
