@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans-Regular"),
    url("./Assets/fonts/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-BlackItalic";
  src: local("Montserrat-BlackItalic"),
    url("./Assets/fonts/Montserrat-BlackItalic.ttf") format("truetype");
}
