.pipelineInfraCesium .cesium-viewer-geocoderContainer {
  display: none !important;
}
* .glass-container {
  background: rgba(255, 255, 255);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);

  border: 1px solid rgba(255, 255, 255, 0.18);
  /* padding: 8px; */
}
