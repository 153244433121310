@import url(./fonts.css);
body {
  font-family: "OpenSans-Regular";
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.5s;
}

/* -----------------------------------------------seekbar css start - aman ------------------------------------ */
.video-layer-images {
  position: relative;
  margin-top: 27px;
  margin-bottom: 16px;
  height: 47px;
  border: 3px solid #ffffff;
}
.video-layer-images:hover {
  transition: 0.2s;
  border: 3px solid #ffca00;
  height: 47px;
}
.video-img > .seekimg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.transition-c:hover {
  transition: 0.3s;
}

/* ------------------------------------------seekbar end --------------------------------------*/

/* ---------------------------------------navbar css start--- aman--------------------------- */
.MuiAvatar-root {
  width: 33px !important;
  height: 33px !important;
  font-size: 20px !important;
  background-color: #ffffff !important;
  border: 1px solid #e5e2dc !important;
  border-radius: 25% !important;
}
.MuiSvgIcon-root {
  color: #b5b4b2;
}
.MuiAvatar-root .MuiSvgIcon-root:hover {
  transition: 0.3s;
  color: #3a3a3c;
}

/* ------------------------------navbar css end--- aman -------------------------------*/
.custom-text > .MuiInputBase-root {
  width: 100%;
  height: 40px !important;
}
[type="text"]:focus {
  box-shadow: none !important;
}
.custom-text > .MuiFormLabel-root {
  font-size: 15px !important;
}

.bg-box-model {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
}
.bg-box-model-list {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 1px;
  border: 1.2px dashed #e8e5dd;
}
.side-btn:hover > .side-btn-icon {
  transition: 0.4s;
  background-color: #a9a7a433;
  width: 42px;
}

.side-btn-icon-onclick {
  background-color: #a9a7a433;
  width: 42px !important;
}

.project-dash {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
.sidebr-project-dash {
  display: flex;
  flex-direction: column;
  width: 20%;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
}
.page-name {
  font-weight: 600;
  font-size: 34px;
  line-height: 2rem;
}
.sidebr-project-dashbord {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-left: 4px;
  padding-right: 1px;
  margin-left: 20%;
  background-color: #f4f4f4;
}
.overflowhidden {
  overflow-x: hidden;
}
.navbar-section {
  display: none;
}

.text-nav {
  font-weight: 500;
  color: #b5b4b4;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  font-size: 19px;
}
.parent-text-nav:hover > .text-nav {
  color: #3a3a3c;
  font-weight: 500;
}
.parent-text-nav {
  margin-bottom: 15px;
}
.parent-text-nav:hover {
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 15px;
  border-radius: 10px;
  transition: 0.3s;
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px;
  background-color: #efefef;
}
.parent-text-nav-before {
  border-radius: 10px;
  transition: 0.3s;

  box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px;
  background-color: #efefef;
}
.parent-text-nav-before > .text-nav {
  color: #3a3a3c;
}
.font-icon {
  width: 28px;
}
.user-details::after {
  content: "";
  margin-left: 12px;
  margin-right: 12px;
  border-right: 0.4px dashed #bdbdbd;
}
.top-b-nav ::after {
  content: "";
  padding-top: 20px;
  border-top: 1px dashed #b9b8b8;
}
/*--------------------------------- end of nav css : aman ---------------------------*/
.nav-top {
  z-index: 2;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: sticky;
  border-bottom: 1px solid #4a484424;
  justify-content: space-between;
  top: 0;
}

.video-border {
  border: 2px solid #ffca00;
}
.leaflet-control-attribution {
  display: none;
}
.rotated {
  transform: rotate(180deg);
}
/* .reportDataTableHead div {
  border-right: 1px #9ca3afa8 solid;
} */
.reportDataTableRow {
  border: 1px #9ca3afa8 solid;
}
.reportDataTableRow div {
  border-right: 1px #9ca3afa8 solid;
}
.reportDataTableRow:nth-child(even) {
  background-color: #e5e2dc3d;
}

.cesium-baseLayerPicker-dropDown {
  z-index: 11;
}
